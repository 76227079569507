import { computed, ref } from 'vue'
import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { UserData, User } from '@/interfaces/UserDataInterface'
import { defineStore } from 'pinia'
import { useApiJsonValidator } from '@/composables/useApiJsonValidator'
import userValidator from '@/jsonValidator/userValidator.json'
import { useDateUtils } from '~/composables/useDateUtils'

export const useUserStore = defineStore(
  'userStore',
  () => {
    const { userRepository } = useApiRepositories()
    const { validateData } = useApiJsonValidator()

    const user = ref<User>(null)
    const onUpdateData = ref(false)
    const onUpdatePassword = ref(false)
    const userLocalePreference = ref('fr')
    const userLogWithSso = ref(false)
    const setUserStore = (value) => {
      user.value = value
    }

    const fetchUser = async () => {
      try {
        const result = await userRepository.fetchUser()
        if (result) {
          const isValidData = validateData(userValidator, result.data)
          if (isValidData) setUserStore(result.data)
        }
      } catch (error) {
        console.log('Unable to fetch user', error)
      }
    }

    const updateUser = async (updateData: UserData) => {
      onUpdateData.value = true
      try {
        const update = await userRepository.update(updateData)
        if (update) {
          onUpdateData.value = false
          refreshUser()
          return true
        } else {
          onUpdateData.value = false
          return false
        }
      } catch (error) {
        onUpdateData.value = false
        console.log('Unable to update user', error)
      }
    }

    const updatePassword = async (
      currentPassword: string,
      newPassword: string,
      confirmPassword: string
    ) => {
      onUpdatePassword.value = true
      try {
        const update = await userRepository.updatePassword(
          currentPassword,
          newPassword,
          confirmPassword
        )
        if (update) {
          onUpdatePassword.value = false
          refreshUser()
          return true
        } else {
          onUpdatePassword.value = false
        }
      } catch (error) {
        onUpdatePassword.value = false
        console.log('Unable to update user', error)
      }
    }

    const clearUser = () => {
      user.value = null
      userLogWithSso.value = false
    }

    const refreshUser = () => {
      fetchUser()
    }

    const emailVerified = computed(() => {
      return user.value && user.value.email_verified_at !== null
    })

    const userTrialPlan = computed(() => {
      return (
        user.value &&
        user.value.current_plan &&
        user.value.current_plan.is_base_trial
      )
    })

    const userCurrentPlan = computed(() => {
      return (
        user.value && user.value.current_plan && user.value.current_plan.name
      )
    })

    const userCurrentPlanObject = computed(() => {
      return user.value && user.value.current_plan
    })

    const userPlanDeactivated = computed(() => {
      return (
        user.value &&
        user.value.current_plan &&
        user.value.current_plan.disabled
      )
    })

    const isUserEnterprise = computed(() => {
      return (
        user.value &&
        user.value.current_plan &&
        user.value.current_plan.is_enterprise
      )
    })

    const isUserAccountOwner = computed(() => {
      return (
        user.value &&
        user.value.current_plan &&
        user.value.current_plan.is_owner_of_account
      )
    })

    const isEngageAccount = computed(() => {
      return (
        user.value &&
        user.value.current_plan &&
        user.value.current_plan.is_engage_account
      )
    })

    const { timestampToDate } = useDateUtils()
    const reinitializingIn = computed(() => {
      if (user.value) {
        const currentPlanPeriodEnd = user.value.current_plan.period.end
        return timestampToDate(currentPlanPeriodEnd)
      }
      return 0
    })

    return {
      fetchUser,
      user,
      updateUser,
      updatePassword,
      onUpdateData,
      refreshUser,
      clearUser,
      emailVerified,
      setUserStore,
      userTrialPlan,
      userCurrentPlan,
      userPlanDeactivated,
      userLocalePreference,
      reinitializingIn,
      userCurrentPlanObject,
      isUserEnterprise,
      isUserAccountOwner,
      userLogWithSso,
      isEngageAccount,
    }
  },
  {
    // @ts-ignore
    persist: true,
  }
)
